import styled from 'styled-components'
import media from "styled-media-query"
import { RightArrow, LeftArrow } from '@styled-icons/boxicons-solid'
import { MinusCircle } from '@styled-icons/evaicons-solid'

import * as V from '../../styles/variables'

const Icon = `
  display: block;
  height: 2rem;
  width: 2rem;
  margin: .5rem;
`
export const DisabledIcon = styled(MinusCircle)`
  ${Icon}
  display:inline-block;
  color: var(--disabledIcon);
  display: flex;
  justify-content: flex-end;

  transition: transform ${V.Transition.default}; /* Animation */
  &:focus,
  &:hover {
    transform: scale(1.5);
  }
`
export const ArrowR = styled(RightArrow)`
  ${Icon}
  display:inline-block;
  color: var(--shellText);
  display: flex;
  justify-content: flex-end;

  transition: transform ${V.Transition.default}, color ${V.Transition.default} ease; /* Animation */
  &:focus,
  &:hover {
    //transform: scale(1.5);
    transform: translateX(1rem);
    color: var(--highlight);
  }
`
export const ArrowL = styled(LeftArrow)`
  ${Icon}
  display:inline-block;
  color: var(--shellText);
  display: flex;
  justify-content: flex-start;

  transition: transform ${V.Transition.default}, color ${V.Transition.default} ease; /* Animation */
  &:focus,
  &:hover {
    //transform: scale(1.5);
    transform: translateX(-1rem);
    color: var(--highlight);
  }
`

export const PaginationWrapper = styled.section`
  align-items: center;
  border-top: 1px solid var(--borders);
  color: var(--texts);
  display: flex;
  padding: 1.5rem 3rem;
  justify-content: space-between;

  ${media.lessThan("medium")`
    font-size: .8rem;
    padding: 1rem;
  `}
  
  a {
    color: var(--texts);
    text-decoration: none;
    transition: color 0.5s;
    &:hover {
      color: var(--highlight);
    }
  }

  p {
    display: inline-block;
    //font-family: "72";
  }
`
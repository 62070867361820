import React from "react"
import propTypes from "prop-types"
//import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import getThemeColor from "../../utils/getThemeColor"
import * as S from "./styled"

const Pagination = ({
  isFirst,
  isLast,
  currentPage,
  numPages,
  prevPage,
  nextPage,
}) => (
  <S.PaginationWrapper>
    {!isFirst ? (
      <AniLink 
        to={prevPage}
        cover
        direction="left"
        bg={getThemeColor()}
        duration={0.6}
      >
        <S.ArrowL></S.ArrowL>
      </AniLink>
    ) : (<S.DisabledIcon></S.DisabledIcon>)}
    <p style={{marginLeft: 'auto', marginRight: 'auto'}}>
      {currentPage} de {numPages}
    </p>
    {!isLast ? (<AniLink
                  to={nextPage}
                  cover
                  direction="right"
                  bg={getThemeColor()}
                  duration={0.6}
                >
                  <S.ArrowR></S.ArrowR>
                </AniLink>) : (<S.DisabledIcon></S.DisabledIcon>)}
  </S.PaginationWrapper>
)

Pagination.propTypes = {
  isFirst: propTypes.bool.isRequired,
  isLast: propTypes.bool.isRequired,
  currentPage: propTypes.number.isRequired,
  numPages: propTypes.number.isRequired,
  prevPage: propTypes.string,
  nextPage: propTypes.string,
}

export default Pagination
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostItem from "../components/postitem"
import Pagination from '../components/pagination'

import * as S from "../components/listwrapper/styled"

const BlogList = props => {
  const postList = props.data.allMarkdownRemark.edges

  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages

  const routePagePath = '/blog/'

  const prevPage = currentPage - 1 === 1 ? `${routePagePath}` : `${routePagePath}page/${currentPage - 1}`
  const nextPage = `${currentPage + 1}`

  return (
    <Layout>
      <SEO title="Home" />
      <S.ListWrapper>
        {postList.map(
          ({
            node: {
              frontmatter: { background, category, date, description, title, author },
              timeToRead,
              fields: { slug, 
                        readingTime: {
                                      minutes,
                                      text,
                                      time,
                                      words
                        }, 
                      }
                    },
          }) => (
            <PostItem
              slug={slug}
              background={background}
              category={category}
              date={date}
              timeToRead={Math.ceil(minutes)}
              title={title}
              description={description}
              author={author}
            />
          )
        )}
      </S.ListWrapper>
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Layout>
  )
}

export const query = graphql`
  query PostList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              minutes
              text
              time
              words
            }
          }
          frontmatter {
            background
            category
            date(locale: "pt-br", formatString: "DD MMM YYYY")
            description
            title
            author
          }
          timeToRead
        }
      }
    }
  }
`

export default BlogList
import styled from "styled-components"

export const ListWrapper = styled.div`
  width: 50%;
  margin-left: 25%;

  body#grid & {
    background-color: var(--borders);
    border-bottom: 1px solid var(--borders);
    display: grid;
    grid-area: posts;
    grid-gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
`